import React, { useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button } from "reactstrap";
import { ClinicusLogo, LogoClinicusLight } from '@sciometrix/scio-icons/svg/icons';

import loginBg from "../assets/img/Login2.svg";
import logo from "../assets/img/Logo.svg";

import Input from "antd/es/input/Input";
import { useDispatch } from "react-redux";
import { loginUserThunk } from "store/userSlice";
import { toastNotify } from "common/Utils/toastNotify";

const SignIn = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch(); 
    const handleSignIn = async () => { 
        const { payload } = await dispatch(loginUserThunk({ username, password }));

        if (payload[0]) {
            toastNotify("Invalid credentials!", "error", "text-sm");
        }
    }

    return (
        <div className="flex w-full gap-10 h-screen">
            <div
                className="w-[60%] hidden md:block"
                style={{ background: "linear-gradient(180.02deg, #BDD3E6 0.02%, rgba(219, 228, 235, 0.2) 99.98%)" }}
            >
                <div className="h-[25%] 2xl:h-[20%] px-6 py-10">
                    <LogoClinicusLight className="h-16"/>
                    <div className="font-semibold text-xl text-[#062E55] pl-2">
                        Leading solution to enhance Patient Outcomes & Provider Efficiency
                    </div>
                </div>
                <div className="px-4 h-[75%] 2xl:h-[80%] flex justify-center">
                    <div className="h-full overflow-hidden">
                        <img src={loginBg} alt="login image..." className="h-full relative bottom-[-24px]" />
                    </div>
                </div>
            </div>

            <div className="flex items-center flex-col w-full md:w-max justify-center md:items-start">
                {/* <LogoClinicusLight className="h-14 md:hidden"/> */}
                <div className="flex items-center font-medium text-2xl md:hidden gap-2">
                    <ClinicusLogo className="h-16 w-16"/> <span>CLINICUS</span>
                </div>
                <div className="flex flex-col gap-5">
                    <div className="font-bold text-2xl text-[#062E55]">Start your journey with us.</div>
                    <Input placeholder="Enter username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                    <Input placeholder="Enter password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <Button
                        className="max-w-[352px] bg-[#1874DF] flex items-center gap-3 justify-center text-lg font-semibold"
                        onClick={handleSignIn}
                    >
                        Login <ArrowRightIcon className="w-5 h-5" strokeWidth={2} />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
