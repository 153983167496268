import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import { get, isEmpty, isEqual, orderBy } from 'lodash';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './ClinicusGridV2.css';

import { axiosGet, axiosPost } from '../../utils/axiosAsync/async';
import { gridConfigs, textConfigs } from '../../configs/appConfigs';

import AdvancedSearch from '../AdvancedSearch/AdvancedSearch';
import ClinicusDropdown from '../Form/ClinicusDropdown';
import ErrorWrapper from '../Error/ErrorWrapper';
import LoadingWrapper from '../LoadingWrapper';
import AddModalView from './AddModalView';
import Toolbar from './Toolbar';

const CaretUp = ({ size = 16, color = 'currentColor' }) => {
    return (
        <svg style={{ width: size, height: size, color: color }} className="fill-current" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="butt" strokeLinejoin="miter" strokeWidth="2" d="M5 15l7-7 7 7"></path>
        </svg>
    );
}

const CaretDown = ({ size = 16, color = 'currentColor' }) => {
    return (
        <svg style={{ width: size, height: size, color: color }} className="fill-current" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="butt" strokeLinejoin="miter" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
    );
}

const CaretUpDown = ({ size = 16, color = 'white' }) => {
    return (
        <svg style={{ width: size, height: size, color: color }} className="fill-current" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="butt" strokeLinejoin="miter" strokeWidth="2" d="M5 8l7-7 7 7"></path>
            <path strokeLinecap="butt" strokeLinejoin="miter" strokeWidth="2" d="M19 13l-7 7-7-7"></path>
        </svg>
    );
}

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const getExistingQueryParams = (gridId) => {
    const qParams = new URLSearchParams(window.location.search);
    const qPage = qParams.get(gridId ? `qPage_${gridId}` : 'qPage');
    const qSizePerPage = qParams.get(gridId ? `qSizePerPage_${gridId}` : 'qSizePerPage');

    return {
        qPage: qPage ? Number(qPage) : 1,
        qSizePerPage: qSizePerPage ? Number(qSizePerPage) : 10
    };
};

const ClinicusGridV2 = forwardRef((props, ref) => {
    const { formatters, colAttrs, dataFormatter } = props;

    const [gridCols, setGridCols] = useState([]);
    const [advancedSearchCriterias, setAdvancedSearchCriterias] = useState([]);
    const [searchCriterias, setSearchCriterias] = useState(props.searchCriterias || []);
    const [mandatoryCriterias, setMandatoryCriterias] = useState(props.mandatoryCriterias || []);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isPageDropdownOpen, setIsPageDropdownOpen] = useState(false);
    const [sort, setSort] = useState(props.sort || {});
    const [gridDataUrl, setGridDataUrl] = useState(props.gridDataUrl);
    const [columns, setColumns] = useState(props.isSimpleGrid ? props.columns : []);
    const [docs, setDocs] = useState(props.isSimpleGrid ? props.data : []);
    const [objectId, setObjectId] = useState(props.objectId || "");
    const [keyField, setKeyField] = useState(props.isSimpleGrid ? props.keyField : '');
    const [total, setTotal] = useState(0);
    const [formFields, setFormFields] = useState(props.isSimpleGrid ? props.formFields : []);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [title, setTitle] = useState("");

    const { qPage, qSizePerPage } = getExistingQueryParams(props?.gridId);
    const [page, setPage] = useState(qPage);
    const [sizePerPage, setSizePerPage] = useState(qSizePerPage);

    useEffect(() => {
        const { qPage, qSizePerPage } = getExistingQueryParams(props?.gridId);
        setPage(qPage);
        setSizePerPage(qSizePerPage);
    }, [window.location.search]);


    const prevState = usePrevious({ page, sort, gridDataUrl, sizePerPage, searchCriterias, advancedSearchCriterias, mandatoryCriterias }) || {};

    const scrollRef = useRef(null);

    const gridRef = useCallback((node) => {
        if (node && props.gridClassName) {
            const gridDiv = node.querySelector(".react-bootstrap-table");

            if (gridDiv) {
                gridDiv?.classList?.add(...props.gridClassName.split(" "));
            }
        }

        scrollRef.current = node;
    });

    useEffect(() => {
        applyColumnAttributes();
    }, [columns, formatters, colAttrs]);

    useEffect(() => {
        if (!isEmpty(prevState) && props.gridDataUrl !== prevState.gridDataUrl) {
            setGridDataUrl(props.gridDataUrl);
        }

        if (!isEmpty(prevState) && !isEqual(props.searchCriterias, prevState.searchCriterias)) {
            setSearchCriterias(props.searchCriterias || []);
        }

        if (!isEmpty(prevState) && !isEqual(props.mandatoryCriterias, prevState.mandatoryCriterias)) {
            setMandatoryCriterias(props.mandatoryCriterias || []);
        }

        if(props.dummyData){
            getGridData();
        }
        // if(!props?.isSimpleGrid) {
        //     getGridData();
        // }
    }, [props.gridDataUrl, props.searchCriterias, props.mandatoryCriterias, props?.dummyData]);

    useEffect(() => {
        const shouldFetchData = prevState && (
            page !== prevState.page || (sizePerPage !== prevState.sizePerPage) ||
            (sort && !isEqual(sort, prevState.sort)) || (gridDataUrl && !isEqual(gridDataUrl, prevState.gridDataUrl))
            || (searchCriterias && !isEqual(searchCriterias, prevState.searchCriterias))
            || (advancedSearchCriterias && !isEqual(advancedSearchCriterias, prevState.advancedSearchCriterias))
            || (mandatoryCriterias && !isEqual(mandatoryCriterias, prevState.mandatoryCriterias))
        ) && !props?.isSimpleGrid;

        if (props.scroll === true && docs.length === 0) {
            getGridData();
            return;
        }

        if (props.scroll !== true && shouldFetchData) {
            getGridData();
        }
    }, [page, gridDataUrl, sort, sizePerPage, searchCriterias, advancedSearchCriterias, mandatoryCriterias]);

    useImperativeHandle(ref, () => ({
        refreshGrid: () => {
            getGridData();
        },
        scrollToGrid: () => {
            scrollRef.current?.scrollIntoView({ behaviour: 'smooth' });
        }
    }));

    const refreshGridData = () => {
        getGridData();
    }

    const addAdvancedSearchCriterias = (advancedSearchCriterias) => {
        setAdvancedSearchCriterias(advancedSearchCriterias);
    }

    const clearAdvancedSearchCriterias = () => {
        setPage(1);
        setAdvancedSearchCriterias([]);
    }

    const getGridData = async () => {
        const { onLoadDataCompletion } = props;
        const criterias = [...searchCriterias, ...advancedSearchCriterias, ...mandatoryCriterias];

        const bodyPayload = {
            page: page || 1,
            limit: sizePerPage || 10,
            sort: JSON.stringify(sort || {}),
            hasLimit: !(props?.scroll === true),
            criterias: encodeURIComponent(JSON.stringify(criterias))
        };

        setLoading(true);

        try {
            if (props.dummyData) {
                const {data: rawData = [], columns = [], keyField = '', total = 0, formFields = [], title = ''} = props.dummyData;
                const data = dataFormatter ? dataFormatter(rawData) : rawData;
                
                setDocs(data);
                setTitle(title);
                setColumns(columns);
                setKeyField(keyField);
                setTotal(total);
                setFormFields(formFields);
                setError("");
                onLoadDataCompletion && onLoadDataCompletion(props.dummyData);
            }
            else {
                const [err, resp] = await axiosGet(gridDataUrl, bodyPayload);

                if (err) {
                    console.log(err);
                    const error = get(err, "message", "Something went wrong!");
                    setLoading(false);
                    setError(error);
                    onLoadDataCompletion && onLoadDataCompletion(undefined);
                    return;
                }

                const rawData = get(resp, 'data.data', []);

                const data = dataFormatter ? dataFormatter(rawData) : rawData;
                const columns = get(resp, 'data.columns', []);
                const keyField = get(resp, 'data.keyField', '');
                const total = get(resp, 'data.total', 0);
                const formFields = get(resp, 'data.formFields', []);
                const title = get(resp, 'data.title', "");

                setDocs(data);
                setTitle(title);
                setColumns(columns);
                setKeyField(keyField);
                setTotal(total);
                setFormFields(formFields);
                setError("");
                onLoadDataCompletion && onLoadDataCompletion(get(resp, 'data', {}));
            }
        } catch (err) {
            setError(err.message);
            onLoadDataCompletion && onLoadDataCompletion(undefined);
        }
        setLoading(false);
    }

    const applyColumnAttributes = () => {
        const gridCols = columns.map(col => {
            const { formatter, headerStyle, style, attrs } = col;
            const colWithAttrs = { ...col };

            // Apply font weight to headerStyle
            if (props?.colStyle) {
                colWithAttrs.headerStyle = { ...headerStyle, ...props.colStyle };
            }

            if (formatter && formatters[formatter]) {
                colWithAttrs.formatter = formatters[formatter];
            }

            if (headerStyle && colAttrs?.headerStyle?.[headerStyle]) {
                colWithAttrs.headerStyle = { ...colWithAttrs.headerStyle, ...colAttrs.headerStyle[headerStyle] };
            }

            if (style && colAttrs?.style?.[style]) {
                colWithAttrs.style = { ...style, ...colAttrs.style[style] };
            }

            if (attrs && colAttrs?.attrs?.[attrs]) {
                colWithAttrs.attrs = { ...attrs, ...colAttrs.attrs[attrs] };
            }

            if (!colWithAttrs.headerStyle) {
                colWithAttrs.headerStyle = { width: 180 };
            }

            return colWithAttrs;
        });

        setGridCols(gridCols);
    };

    // handler function for page and page size change
    const handlePageAndSizeChange = (page, sizePerPage) => {
        const currentParams = new URLSearchParams(window.location.search);

        // Determine parameter names dynamically
        const pageParam = props.gridId ? `qPage_${props.gridId}` : 'qPage';
        const sizePerPageParam = props.gridId ? `qSizePerPage_${props.gridId}` : 'qSizePerPage';

        // Update URL parameters
        currentParams.set(pageParam, page || 1);
        currentParams.set(sizePerPageParam, sizePerPage || 10);

        const newParams = new URLSearchParams();
        currentParams.forEach((value, key) => {
            if (key !== pageParam && key !== sizePerPageParam) {
                newParams.append(key, value);
            }
        });

        // Set updated pagination params
        newParams.set(pageParam, page || 1);
        newParams.set(sizePerPageParam, sizePerPage || 10);

        const qParams = "?" + newParams.toString();
        window.history.replaceState(null, null, qParams);

        setPage(page);
        setSizePerPage(sizePerPage);
    };


    const getPaginationTotal = (from, to, size) => {
        return (
            <React.Fragment>
                <span className="react-bootstrap-table-pagination-total font-medium">
                    &nbsp;&nbsp;Showing <b>{from.toLocaleString()}</b> to <b>{to.toLocaleString()}</b> of <b>{size.toLocaleString()}</b> Results
                </span>{" "}
            </React.Fragment>
        );
    }

    const getSizePerPage = ({ options, currSizePerPage, onSizePerPageChange }) => {
        const dropdownOption = options.map(option => ({ label: option.text, value: option.page }));
        const selectedOption = { label: currSizePerPage, value: currSizePerPage };

        return (
            <ClinicusDropdown
                isOpen={isPageDropdownOpen}
                toggle={() => setIsPageDropdownOpen(!isPageDropdownOpen)}
                options={dropdownOption}
                displayText={selectedOption}
                dropdownClassName="w-fit"
                size="sm"
                onClickOption={(option) => onSizePerPageChange(option.value)}
            />
        );
    }

    const getPageOption = (option) => {
        const constantClassNames = "border border-[#444444] border-solid rounded-full flex items-center justify-center h-5 w-5";

        if (option.title === "first page") {
            return (
                <div className={constantClassNames}>
                    <ChevronDoubleLeftIcon className="w-3 h-3 " />
                </div>
            );
        } else if (option.title === "previous page") {
            return (
                <div className={constantClassNames}>
                    <ChevronLeftIcon className="w-3 h-3" />
                </div>
            );
        } else if (option.title === "next page") {
            return (
                <div className={constantClassNames}>
                    <ChevronRightIcon className="w-3 h-3" />
                </div>
            );
        } else if (option.title === "last page") {
            return (
                <div className={constantClassNames}>
                    <ChevronDoubleRightIcon className="w-3 h-3" />
                </div>
            );
        } else {
            return (
                <div>
                    {option.title}
                </div>
            );
        }
    }

    const getPageList = ({ pages, onPageChange }) => {
        return (
            <div className="flex gap-2 w-fit items-center">
                {pages.map(option => (
                    <div key={option.page} onClick={() => onPageChange(option.page)} className={`${option.active ? `${textConfigs.colors.defaults.textColorClassName} font-bold underline` : "font-medium text-[#484848]"} cursor-pointer`}>
                        {getPageOption(option)}
                    </div>
                ))}
            </div>
        );
    }

    const paginationOptions = {
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
        ],
        page: page, // Specify the default page to be selected
        sizePerPage: sizePerPage, // Specify the number of rows per page
        nextPageText: 'Next', // Text of next page button
        prePageText: 'Prev', // Text of previous page button
        showTotal: true, // Display total number of rows
        paginationSize: 4, // Number of page buttons to display
        hideSizePerPage: false, // Hide the sizePerPage dropdown
        hidePageListOnlyOnePage: true, // Hide pagination if only one page exists
        firstPageText: 'First', // Text of the first page button
        lastPageText: 'Last', // Text of the last page button,
        totalSize: total,
        onPageChange: (page, sizePerPage) => handlePageAndSizeChange(page, sizePerPage),
        onSizePerPageChange: (sizePerPage, page) => handlePageAndSizeChange(page, sizePerPage),
        paginationTotalRenderer: getPaginationTotal,
        sizePerPageRenderer: getSizePerPage,
        pageListRenderer: getPageList
    };

    const handleSort = (sortCriteria) => {
        const { sortField, sortOrder } = sortCriteria;

        setSort({ [sortField]: sortOrder });

        if (props.scroll === true) {
            const sortedDocs = orderBy(docs, [sortField], [sortOrder]);
            setDocs(sortedDocs);
        }
    }

    const handleTableChange = (param, values) => {
        if (param === "sort") handleSort(values);
    }

    const handleAfterSaveCell = async (oldValue, newValue, row, column) => {
        if (!oldValue && !newValue) return;
        setLoading(true);

        try {
            const url = `/updateObject/${objectId}`;
            const payload = { keyField, keyFieldValue: row[keyField], colKey: column["dataField"], colVal: newValue };

            const [err] = await axiosPost(url, payload);
            toast.success("Column is updated!");

            if (err) {
                setError(err?.message || err);
            }
        } catch (err) {
            toast.success("Column updatation failed!");
            setError(err?.message || err);
        }

        setLoading(false);
    };

    const onClickAdd = () => {
        setAddModalOpen(true);
    }

    const handleAddRow = async (formVals) => {
        try {
            const url = `/createObject/${objectId}`;
            const payload = {
                ...formVals,
                createddate: new Date(),
                isdeleted: false
            }

            const [err] = await axiosPost(url, payload);

            if (err) {
                setError(err?.message || err);
                return;
            }
            toast.success("Row is added!");
            refreshGridData();
        } catch (err) {
            toast.success("Row addition failed!");
            setError(err?.message || err);
        }
    }

    const onClickDelete = async () => {
        setLoading(true);

        try {
            const url = `/deleteObject/${objectId}`;
            const payload = { keyField, keyFieldValues: selectedRows };

            const [err] = await axiosPost(url, payload);
            setSelectedRows([]);
            toast.success("Row is deleted!");

            if (err) {
                setError(err?.message || err);
                setLoading(false);
                return;
            }
            setError("");
            refreshGridData();
        } catch (err) {
            toast.success("Row deletion failed!");
            setError(err?.message || err);
        }
    };

    const getRowClasses = (row, rowIndex) => {
        return gridConfigs.colors.defaults.rowBgClassName;
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: (row, isSelect) => {
            const selectedIds = isSelect ? [...selectedRows, row.sfid] : selectedRows.filter(id => id !== row.sfid);
            setSelectedRows(selectedIds);
        },
        selected: selectedRows
    };

    return (
        <ErrorWrapper error={error} position="top">
            <LoadingWrapper loading={loading}>
                {gridCols.length ?
                    <div ref={gridRef}>
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                            {props.removeGridTitle !== true
                                ? props.CustomTitleComponent || <div className="text-base font-semibold">{props.title}</div>
                                : null}
                            {/* <Toolbar
                                {...props}
                                disabledProps={{
                                    isDeleteDisabled: isEmpty(selectedRows)
                                }}
                                columns={gridCols}
                                objectId={objectId}
                                functions={{
                                    onClickAdd,
                                    onClickDelete,
                                    refreshGridData,
                                    addAdvancedSearchCriterias,
                                    clearAdvancedSearchCriterias,
                                    showAdvancedSearch: () => setShowAdvancedSearch(true),
                                    closeAdvancedSearch: () => setShowAdvancedSearch(false)
                                }}
                            /> */}
                        </div>
                        <div className={`w-full rounded-2xl border-[#D8D8D8] shadow-table ${props?.tableFontSize}`}>
                            <BootstrapTable
                                keyField={keyField || "id"}
                                data={docs}
                                remote={{ pagination: props.isSimpleGrid ? false : true, sort: props.isSimpleGrid ? false : true }}
                                onTableChange={(param, values) => handleTableChange(param, values)}
                                columns={gridCols}
                                classes="table-fixed-header"
                                // striped={true}
                                hover={props.hover === undefined ? true : props.hover}
                                condensed={true}
                                noDataIndication={"Table is empty"}
                                // selectRow={selectRow}
                                // cellEdit={cellEditFactory({ mode: 'dbclick', blurToSave: true, afterSaveCell: handleAfterSaveCell })}
                                pagination={props?.scroll === true ? null : paginationFactory({ ...paginationOptions })}
                                rowClasses={getRowClasses}
                                sort={{
                                    sortCaret: (order, column, ...props) => {
                                        if (!order) return (<span className="ml-1 inline-block align-middle">
                                            <CaretUpDown />
                                        </span>
                                        );
                                        else if (order === 'asc') return (<span className="ml-1 inline-block align-middle">
                                            <CaretUp />
                                        </span>
                                        );
                                        else if (order === 'desc') return (<span className="ml-1 inline-block align-middle">
                                            <CaretDown />
                                        </span>
                                        );
                                        return null;
                                    }
                                }}
                            />
                        </div>
                    </div> :
                    <div className="h-16 p-3">No grid found!</div>
                }

                <Drawer
                    title={
                        <div className="flex justify-between items-center">
                            Filter By
                        </div>
                    }
                    placement="right"
                    width={"30%"}
                    closable={true}
                    // styles={{ body: { padding: sidebarConfigs.defaultPadding }, header: { padding: sidebarConfigs.defaultPadding } }}
                    onClose={() => setShowAdvancedSearch(false)}
                    open={false}
                >
                    data
                </Drawer>

                {addModalOpen &&
                    <AddModalView
                        formFields={formFields}
                        isOpen={addModalOpen}
                        onClose={() => setAddModalOpen(false)}
                        onSubmit={handleAddRow}
                    />
                }

                {showAdvancedSearch &&
                    <AdvancedSearch
                        advancedSearchCriterias={advancedSearchCriterias}
                        setAdvancedSearchCriterias={setAdvancedSearchCriterias}
                        columns={columns}
                        addAdvancedSearchCriterias={addAdvancedSearchCriterias}
                        isOpen={showAdvancedSearch}
                        hideAdvancedSearch={() => setShowAdvancedSearch(false)}
                    />
                }
            </LoadingWrapper>
        </ErrorWrapper>
    );
});

ClinicusGridV2.propTypes = {
    keyField: PropTypes.string,
    data: PropTypes.array,
    columns: PropTypes.array,
    loading: PropTypes.bool,
    formatters: PropTypes.object
};

export default ClinicusGridV2;
