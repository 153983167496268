import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTH_STATUS } from "enums/authEnum";
import { axiosGet } from "utils/axiosAsync/async";
import { filterNavItems } from "./navSlice";
import { loginUser } from "msalAuth";

// Thunk to handle asynchronous login
export const loginUserThunk = createAsyncThunk(
    "user/loginUser",
    async ({ username, password }, thunkAPI) => {
        try {
            const [error, response] = await loginUser(username, password);
            if (error) {
                return thunkAPI.rejectWithValue(error); // Handles rejection
            }
            return ["", response]; // Success
        } catch (err) {
            return thunkAPI.rejectWithValue("An unexpected error occurred"); // Handle unexpected errors
        }
    }
);

// Thunk to get user details after login
export const getUserDetails = createAsyncThunk(
    'user/getUserDetails',
    async (userReqParams, { dispatch }) => {
        try {
            // Simulated API call to fetch user details
            const user = {
                providerId: "20241028140325-AeTLyh7f-PRO-32591",
                email: "mj@sciometrix.com",
                phone: "344546540",
                clinicusRole: "textUser",
                permissions: [
                    "CLINICUS_TCM_DASHBOARD_READ",
                    "CLINICUS_TCM_DASHBOARD_EDIT"
                ],
                name: "Michael Jackson"
            };

            // Dispatch to filter navigation items based on user permissions
            dispatch(filterNavItems(user.permissions));
            return user;
        } catch (err) {
            throw new Error("Failed to fetch user details");
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        status: AUTH_STATUS.LOGGED_OUT, // Initial status
        activeAccount: false,
        activeEmail: null,
        error: null
    },
    reducers: {
        updateUserInfo: (state, action) => {
            state.user = action.payload;
        },
        logoutUser: (state) => {
            state.user = null;
            state.status = AUTH_STATUS.LOGGED_OUT;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        // Handle login thunk states
        builder.addCase(loginUserThunk.pending, (state) => {
            state.activeAccount = false;
            state.error = null;
        });
        builder.addCase(loginUserThunk.fulfilled, (state, action) => {
            state.activeAccount = true;
            state.activeEmail = `mj@sciometrix.com`;
        });
        builder.addCase(loginUserThunk.rejected, (state, action) => {
            state.activeAccount = false;
            state.error = action.payload || "Login failed"; // Set error message
        });

        // Handle getUserDetails thunk states
        builder.addCase(getUserDetails.pending, (state) => {
            state.status = AUTH_STATUS.LOGGING_IN; // Set as logging in during fetching user details
        });

        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.status = AUTH_STATUS.LOGGED_IN;
            state.user = action.payload; // Set user details after successful fetch
        });

        builder.addCase(getUserDetails.rejected, (state, action) => {
            state.status = AUTH_STATUS.LOGGING_ERROR; // Error in fetching user details
            state.error = action.error.message || "Error fetching user details"; // Set error message
        });
    }
});

export const { updateUserInfo, logoutUser } = userSlice.actions;
export default userSlice.reducer;
