import React, { useEffect } from 'react';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { Toaster } from 'react-hot-toast';
import { get } from 'lodash';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { toastConfigs } from 'configs/appConfigs';
import { getUserDetails, loginUserThunk } from 'store/userSlice';
import { toastNotify } from 'common/Utils/toastNotify';
import { AUTH_STATUS } from 'enums/authEnum';

import AuthorisedRoutes from './AuthorisedRoutes';
import UnauthorisedRoutes from './UnauthorisedRoutes';
// import AmazonConnect from './amazon-connect/amazon-connect';

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const MainContent = () => {
    const { status, error, activeAccount, activeEmail, user } = useSelector(store => store.userSlice);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (activeAccount) {
            dispatch(getUserDetails({ email: activeEmail, isImpersonatedUser: false, actualUserEmail: "" }));
        }
    }, [activeAccount, activeEmail, dispatch]);


    if (error?.message) {
        toastNotify("Error in fetching your details. Please try again later!", "error", "text-sm");
    }

    return (
        <div className="App">
            {/* <AuthenticatedTemplate> */}
                {activeAccount ? (
                    <Spin spinning={status === AUTH_STATUS.LOGGING_IN} tip="Loading your details..." className="h-screen">
                        {status === AUTH_STATUS.LOGGING_IN ? <div>Loading...</div> : null}
                        {status === AUTH_STATUS.LOGGED_IN ? <AuthorisedRoutes /> : null}
                        {status === AUTH_STATUS.LOGGING_ERROR ? <div className="grid place-content-center">Error loading your details...</div> : null}
                    </Spin>
                ) : <UnauthorisedRoutes /> }
            {/* </AuthenticatedTemplate> */}
            {/* <UnauthenticatedTemplate> */}
                
            {/* </UnauthenticatedTemplate> */}
        </div>
    );
}

const App = () => {
    return (
        // <MsalProvider instance={instance}>
        <React.Suspense fallback={<div>Loading please wait...</div>}>
            <MainContent />
            <Toaster toastOptions={toastConfigs.defaults} />
        </React.Suspense>
        // </MsalProvider>
        // <div>
        //     <AmazonConnect/>
        // </div>
    );
}

export default App;
