import { PresentationChartBarIcon } from "@heroicons/react/24/solid";

import { navItemsIconMapping } from "./constants";

export const getNavItemIcon = (itemName) => {
    let icon = navItemsIconMapping[itemName];

    if(!icon)
        icon = <PresentationChartBarIcon className="h-5 w-5" />;

    return icon;
};

export const USER_CREDENTIALS = {
    USERNAME : "test@clinicus",
    PASSWORD : "#$tu77fe883*&MJ"
}
